import { computed, inject, onBeforeUnmount, provide, watch, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import useUserStore from '@/store/user';
import { useConnectSocket } from '@/hooks/webSocket/connect';
import {
  GlobalSocketTypeEnum,
  IDENTITY_TYPE,
  SocketStatusEnum,
  SocketTypeEnum,
} from '@/enum/webSocket';
import mitt from 'mitt';
import { useRoute, useRouter } from 'vue-router';
import useChatListStore from '@/store/chatList';
import { ElNotification } from 'element-plus';
import dayjs from 'dayjs';
import { useDocumentVisibility, useWebNotification } from '@vueuse/core';
import pinia from '@/store';
import { isFunction } from 'lodash-es';
import { requestNotificationPermission } from '@/utils/notification';
import globleNotif from '@/components/globleNotif/index';

// 给其他组件做响使用的emitter
const diffusionEmitter = mitt();

export const useGlobalMsgListener = (type, fn) => {
  if (!isFunction(fn)) {
    throw new Error('useGlobalMsgListener 必须传入一个fn');
  }
  diffusionEmitter.on(type, fn);

  onBeforeUnmount(() => {
    diffusionEmitter.off(type, fn);
  });
};

const useGlobalSocketListener = (router, route) => {
  const emitter = mitt();
  const { emit, on } = emitter;

  const chatListStore = useChatListStore(pinia);
  const userStore = useUserStore(pinia);
  const visibility = useDocumentVisibility();

  const {
    show: showNotification,
    close: closeNotification,
    onClick: onNotificationClick,
  } = useWebNotification({
    title: '听芝销售系统',
    lang: 'zh',
  });

  // 点击消息时打开标签页
  onNotificationClick((event) => {
    window.focus();

    // 如果能拿到数据，跳转到对应的咨询室
    const { data } = event.currentTarget;
    if (!data) return;

    const {
      msg_data: { room_id },
      teacher_id,
    } = data;

    if (!room_id || !teacher_id) return;

    router.push({
      path: `/home/chat/${teacher_id}/room/${room_id}`,
    });
  });

  watchEffect(() => {
    console.log(`[${dayjs().format('YYYY-MM-DD HH:mm:ss')}]visibility: `, visibility.value);
  });

  on(GlobalSocketTypeEnum.MSG_NO_READ_NUM, (res) => {
    console.log(res);
    const { teacher_id, msg_data } = res;

    userStore.updateTeacherNotice(teacher_id, msg_data.no_read_msg_num);
  });

  const handleShowNotification = async (res) => {
    requestNotificationPermission();
    if (visibility.value !== 'hidden') return;

    const { is_me, text, user, updated_at, is_newcomer } = res.msg_data;

    if (is_me) return;

    // 非新用户不需要发送消息通知
    if (!is_newcomer) return;

    // 页面可见时发送系统右下角消息通知
    closeNotification(); // 先关闭上一条消息
    await showNotification({
      title: user.nickname,
      body: text,
      icon: user.avatar,
      data: res,
      timestamp: dayjs.unix(updated_at),
    });
  };

  on(GlobalSocketTypeEnum.SALE_NEW_MSG, (res) => {
    console.log(res);
    const { teacher_uid } = route.params;

    const { teacher_id, msg_data } = res;

    const { is_system } = msg_data;
    if (!is_system) {
      handleShowNotification(res);
    }

    if (teacher_id !== teacher_uid) {
      return;
    }

    chatListStore.updateChatItem(msg_data);
    diffusionEmitter.emit(GlobalSocketTypeEnum.SALE_NEW_MSG, msg_data);

    // 页面可见时更新消息数
    if (visibility.value === 'visible') {
      const token = userStore.getTeacherTokenByUid(teacher_id);
      chatListStore.getMsgNum({ token });
      return;
    }
  });

  on(GlobalSocketTypeEnum.SALE_CHAT_READ_MSG, (res) => {
    console.log(res);
    const { teacher_uid } = route.params;

    const { teacher_id, msg_data } = res;
    if (teacher_id !== teacher_uid) {
      return;
    }

    const user_id = msg_data.uid;
    chatListStore.readChatItem(teacher_id, user_id);
    diffusionEmitter.emit(GlobalSocketTypeEnum.SALE_CHAT_READ_MSG, {
      teacher_id,
      user_id,
    });
  });

  on(GlobalSocketTypeEnum.SALE_USER_NICKNAME_REMARK, (res) => {
    const { uid, nickname_remark } = res.msg_data;

    chatListStore.updateNicknameRemark(uid, nickname_remark);
    diffusionEmitter.emit(GlobalSocketTypeEnum.SALE_USER_NICKNAME_REMARK, {
      uid,
      nickname_remark,
    });
  });

  // 闪测订单提醒
  on(GlobalSocketTypeEnum.SALE_SC_ORDER_NOTIFICATION, (res) => {
    const { msg } = res.msg_data;
    globleNotif(msg);
  });

  return {
    emit,
  };
};

/**
 * 应用于全局范围的一个web socket
 * 主要使用于未读数更新、消息列表更新等情况下
 * 登录后会自动连接
 */
export const useGlobalSocket = () => {
  const route = useRoute();
  const router = useRouter();
  const userStore = useUserStore();

  const { hasLogin, userInfo } = storeToRefs(userStore);
  const socket_uid = computed(() => userInfo.value?.socket_uid || '');

  watch(
    [hasLogin, socket_uid],
    ([hasLogin, socket_uid], _, onCleanup) => {
      if (!hasLogin || !socket_uid) {
        // 这里只是一些提示。。。不重要
        !hasLogin && console.log('未登录，global socket未连接');
        hasLogin && !socket_uid && console.log('没有获取到socket_uid：', socket_uid);
        return;
      }

      const { close, addResListener, open, send, status } = useConnectSocket(
        '全局',
        {
          from_uid: socket_uid,
          identity_type: IDENTITY_TYPE.SALE,
        },
        {
          debug: false,
          debugName: 'globalSocket',
          onReconnectFailed: () => {
            ElNotification.warning({
              message: '全局消息更新已断开，请刷新（F5）重试',
              duration: 0,
            });
          },
        },
      );

      const stopStatusWatch = watch(status, (value) => {
        console.log(`${dayjs().format('YYYY-MM-DD HH:mm:ss')} globalSocketStatus: `, value);
        if (value === SocketStatusEnum.OPEN) {
          console.log('全局socket链接，更新notice');
          userStore.setDefaultNotReadCount();
        }
      });

      const { emit: emitListener } = useGlobalSocketListener(router, route);

      // 监听 type = send 的socket
      addResListener(SocketTypeEnum.SEND, (res) => {
        const { msg_type } = res.data;

        emitListener(msg_type, res.data);
      });

      // 如果登录状态改变，关闭掉socket连接
      onCleanup(() => {
        stopStatusWatch();
        close();
        console.log('登录状态改变，global socket断开连接');
      });
    },
    {
      immediate: true,
    },
  );
};
