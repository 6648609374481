const {
  VITE_PLATFORM_ID,
  VITE_WEB_SOCKET_URL,
  VITE_BASE_URL,
  VITE_YI_QI_WEN_URL,
  VITE_DOCUMENT_TITLE,
} = import.meta.env;

const config = {
  baseUrl: VITE_BASE_URL,

  // 平台号
  platform: VITE_PLATFORM_ID,

  // webSocket的连接地址
  webSocketUrl: VITE_WEB_SOCKET_URL,

  yiQiWenUrl: VITE_YI_QI_WEN_URL,

  documentTitle: VITE_DOCUMENT_TITLE,
};

export default config;
