import { ElNotification } from 'element-plus';

import style from './index.module.scss';

let onlyNotif = null;
export default function elNotif(msg) {
  if (onlyNotif) {
    onlyNotif.close();
  }
  onlyNotif = ElNotification({
    title: '消息通知',
    message: msg,
    duration: 0,
    customClass: style.globleNotif,
  });
}
