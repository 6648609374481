import { ref } from 'vue';
import { defineStore } from 'pinia';
import User from '@/models/user';
import { saveTeacherTokens } from '@/utils/token';
import { useRoute } from 'vue-router';
import Message from '@/models/message';
import { debounce } from 'lodash-es';

const useUserStore = defineStore(
  'user',
  () => {
    // 是否已登录
    const hasLogin = ref(false);

    // 当前用户的信息
    const userInfo = ref(null);

    const reset = () => {
      hasLogin.value = false;
      userInfo.value = null;
    };

    const login = async ({ username, password }) => {
      const res = await User.login({ username, password }, false);

      const teacherList = res.teacher || [];
      const tokens = teacherList.map((item) => item.token);
      saveTeacherTokens(tokens);
      userInfo.value = res;
      hasLogin.value = true;

      return res;
    };

    const setDefaultNotReadCount = async () => {
      const notReadRes = await Promise.all(
        userInfo.value.teacher.map((item) => Message.getNotReadNum(item.token)),
      );

      userInfo.value.teacher.forEach((item, index) => {
        if (!item.notice) {
          item.notice = notReadRes[index].message;
        }
      });
    };

    const getTeacherByUid = (uid) => {
      const teacherList = userInfo.value.teacher;
      if (!Array.isArray(teacherList)) return null;
      return teacherList.find((item) => item.uid === uid);
    };

    const getTeacherTokenByUid = (uid) => {
      return getTeacherByUid(uid)?.token;
    };

    const updateTeacherNotice = (teacher_id, count) => {
      const teacherList = userInfo.value.teacher;
      if (!teacherList.length) {
        return;
      }

      const item = teacherList.find((item) => item.uid === teacher_id);
      if (!item) return;

      item.notice = count;
    };

    const updateNoticeFromRemote = debounce(async (teacher_id) => {
      const teacher = getTeacherByUid(teacher_id);
      if (!teacher) return;
      const res = await Message.getNotReadNum(teacher.token);
      if (!res) return;
      teacher.notice = res.message;
    }, 5000);

    return {
      hasLogin,
      userInfo,

      reset,
      login,
      updateTeacherNotice,
      setDefaultNotReadCount,
      updateNoticeFromRemote,

      getTeacherByUid,
      getTeacherTokenByUid,
    };
  },
  {
    persist: {
      storage: window.sessionStorage,
    },
  },
);

export default useUserStore;
