import { computed } from 'vue';
import useUserStore from '@/store/user';

function useNotice() {
  const userStore = useUserStore();
  return computed(() => {
    const teacherList = userStore.userInfo?.teacher;
    if (!Array.isArray(teacherList)) return 0;

    return teacherList.reduce((value, item) => {
      return value + item.notice;
    }, 0);
  });
}

export default useNotice;
