const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    children: [
      {
        // uid: 老师的id
        path: 'chat/:teacher_uid',
        name: 'Chat',
        component: () => import('@/views/home/chat/Chat.vue'),
        children: [
          {
            path: 'room/:room_id',
            name: 'Chatroom',
            component: () => import('@/views/home/chat/room/Room.vue'),
          },
        ],
      },
      {
        path: 'order/:teacher_uid',
        name: 'Order',
        component: () => import('@/views/home/order/Order.vue'),
        children: [
          {
            path: 'gao_ji_fu_wu',
            name: 'GaoJiFuWu',
            component: () => import('@/views/home/order/gao_ji_fu_wu/GaoJiFuWu.vue'),
          },
          {
            path: 'shan_ce_wen_da',
            name: 'ShanCeWenDa',
            component: () => import('@/views/home/order/shan_ce_wen_da/ShanCeWenDa.vue'),
          },
          {
            path: 'tong_hua_lian_xian',
            name: 'TongHuaLianXian',
            component: () => import('@/views/home/order/tong_hua_lian_xian/TongHuaLianXian.vue'),
          },
          {
            path: 'reward',
            name: 'Reward',
            component: () => import('@/views/home/order/reward/reward.vue'),
          },
          {
            path: 'live',
            name: 'live',
            component: () => import('@/views/home/order/live/live.vue'),
          },
        ],
      },
      {
        path: 'client-info/:teacher_uid',
        name: 'ClientInfo',
        component: () => import('@/views/home/clientInfo/ClientInfo.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    alias: '/404',
    name: 'NotFound',
    component: () => import('@/views/error/NotFound.vue'),
  },
];

export default routes;
