<template>
  <el-config-provider :locale="ElementZhCn">
    <a-config-provider :locale="AntdZhCn">
      <el-container id="app-container">
        <router-view v-slot="{ Component, route }">
          <transition :name="route.meta.transition || 'fade'" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </el-container>
    </a-config-provider>
  </el-config-provider>
</template>

<script setup>
import ElementZhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { useGlobalSocket } from '@/hooks/webSocket';
import { useRollupNotifyTitle } from '@/hooks/useRollupTitle';
import AntdZhCn from 'ant-design-vue/es/locale/zh_CN';
import useFaviconCount from '@/hooks/useFaviconCount';

useGlobalSocket(); // 全局范围的socket
useRollupNotifyTitle(); // 滚动标题
useFaviconCount(); // 带数字的Favicon
</script>

<style lang="scss">
/* 重置浏览器样式 */
@import '~@/assets/style/reset.scss';

#app-container {
  width: 100vw;
  min-height: 100vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

*::selection {
  background: rgb(51, 141, 233);
  color: #ffffff;
}
</style>
