/***
 * 秒数 格式化成 时:分:秒
 * @param value
 * @param unit
 * @returns {string}
 */
import dayjs from 'dayjs';

export const secondToHms = (value, unit = ':') => {
  const _value = Number(value);
  const remaining = _value % 3600;
  const h = (_value - remaining) / 3600;
  const s = remaining % 60;
  const m = (remaining - s) / 60;

  return [h, m, s].map((item) => item.toString().padStart(2, '0')).join(unit);
};

export const unixTimestampToDateTime = (value, format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs.unix(value).format(format);
};

export const isBefore3Day = (unixTime) => {
  const before3day = dayjs().subtract(3, 'd').unix();
  return Number(unixTime) < before3day;
};

export const isJustNow = (unixTime) => {
  return dayjs().unix() - Number(unixTime) < 60;
};

export const getMsgTime = (unixTime) => {
  const instance = dayjs.unix(unixTime);
  // 刚刚

  if (isJustNow(unixTime)) return '刚刚';

  if (isBefore3Day(unixTime)) return instance.format('MM-DD HH:mm');

  return instance.fromNow();
};
