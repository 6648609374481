import { createApp } from 'vue';
import pinia from '@/store';
import App from './App.vue';
import router from './router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
import User from '@/models/user';

dayjs.locale('zh-cn');
dayjs.extend(relativeTime);

const app = createApp(App);

app.use(pinia);

app.use(router);

(async () => {
  await User.checkAccountState();
  app.mount('#app');
})();
