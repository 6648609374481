<script setup>
import { computed } from 'vue';

const props = defineProps({
  value: Number,
  size: {
    type: Number,
    default: 100,
  },
});

const hasCount = computed(() => !!props.value);
const number = computed(() => (props.value <= 99 ? props.value : '...'));
</script>

<template>
  <svg
    :width="`${size}px`"
    :height="`${size}px`"
    viewBox="0 0 259.987448 260"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>编组 2</title>
    <defs>
      <polygon id="path-1" points="0 260 259.987448 260 259.987448 0 0 0"></polygon>
    </defs>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="logo">
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1"></use>
        </mask>
        <use id="Fill-1" fill="#FDDB00" xlink:href="#path-1"></use>
        <polygon
          id="Fill-1备份"
          fill="#FDDB00"
          mask="url(#mask-2)"
          points="0 260 259.987448 260 259.987448 0 0 0"
        ></polygon>
        <path
          d="M129.969224,113.8275 L130.354971,113.831334 C136.898976,113.961558 142.732666,117.402115 146.019224,123.0945 L196.709224,210.8955 L196.765755,211.002486 C197.851094,213.282733 191.25567,215.4175 184.756224,215.4175 L184.329723,215.415287 C177.994003,215.348926 174.432963,213.791152 172.801224,210.8955 L172.786224,210.8955 L164.376224,196.3285 L164.41974,196.787203 C164.457849,197.247188 164.479224,197.71125 164.479224,198.1815 L164.476244,198.505338 C164.303628,207.875867 156.655065,215.4175 147.245224,215.4175 L146.921386,215.41452 C137.550834,215.241903 130.008224,207.593318 130.008224,198.1815 L130.011205,197.857695 C130.183843,188.48811 137.833406,180.9455 147.245224,180.9455 L147.684287,180.951051 C151.331634,181.043365 154.68707,182.282308 157.433224,184.3025 L129.970224,136.7325 L87.1542242,210.8955 L87.0502937,211.069584 C85.442736,213.662372 81.6446807,215.4175 75.1852242,215.4175 L74.8850025,215.416047 C68.3780127,215.353223 61.770709,213.262091 63.2322242,210.8955 L113.921224,123.0945 C117.272224,117.2915 123.271224,113.8275 129.969224,113.8275 Z M129.975224,44.5825 L130.360914,44.5863352 C136.903975,44.716595 142.737647,48.1580577 146.023224,53.8475 L233.206224,204.8635 L233.359224,205.1255 L233.450163,205.283148 C233.731067,205.769901 234.082739,206.377426 234.45021,207.011427 L234.696928,207.436981 C235.688335,209.146611 236.705224,210.8955 236.705224,210.8955 L236.762377,211.007765 C237.860481,213.39713 231.245547,215.4175 224.738224,215.4175 L224.311498,215.415448 C217.974848,215.35386 214.520333,213.90463 212.770224,210.8955 L129.974224,67.4855 L47.1802242,210.8955 L47.2312242,210.8955 L47.1273575,211.069584 C45.5207355,213.662372 41.7226589,215.4175 35.2622242,215.4175 L34.9620466,215.416047 C28.4559827,215.353223 21.847709,213.262091 23.3092242,210.8955 L23.2452242,210.8955 L26.8622242,204.6535 L113.925224,53.8475 L114.12142,53.5153723 C117.506252,47.9135884 123.404051,44.5825 129.974224,44.5825 Z"
          id="形状结合"
          fill="#FFFFFF"
          mask="url(#mask-2)"
        ></path>
        <path
          v-if="hasCount"
          d="M95.0002242,-0.0005 L260.000224,-0.0005 L260.000224,-0.0005 L260.000224,97.9995 C260.000224,115.672612 245.673336,129.9995 228.000224,129.9995 L95.0002242,129.9995 C77.3271122,129.9995 63.0002242,115.672612 63.0002242,97.9995 L63.0002242,31.9995 C63.0002242,14.326388 77.3271122,-0.0005 95.0002242,-0.0005 Z"
          id="矩形"
          fill="#F72828"
          mask="url(#mask-2)"
        ></path>
        <text
          v-if="hasCount"
          id="99"
          mask="url(#mask-2)"
          font-family="PingFangSC-Regular, PingFang SC"
          font-size="130"
          font-weight="normal"
          fill="#FFFFFF"
          text-anchor="middle"
        >
          <tspan x="160" y="111.9995">{{ number }}</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<style scoped lang="scss"></style>
