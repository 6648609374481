import { watch } from 'vue';
import { ElMessage } from 'element-plus';
import { isObject } from 'lodash-es';
import { SocketTypeEnum } from '@/enum/webSocket';
import mitt from 'mitt';

const dataParse = (value) => {
  let data;
  try {
    data = JSON.parse(value);
  } catch (e) {
    ElMessage.error({
      message: 'socket消息解析失败',
    });

    throw new Error(`socket消息解析失败: ${value}`);
  }

  if (!isObject(data)) {
    ElMessage.error({
      message: 'socket消息异常',
    });

    throw new Error(`socket消息异常: ${value}`);
  }

  return data;
};

const useResponseInterceptors = (connectResult) => {
  const emitter = mitt();

  watch(connectResult.data, (value) => {
    const data = dataParse(value);

    const { type } = data;

    emitter.emit(type, data);
  });

  const resInterceptor = () => {};

  return {
    ...emitter,
    resInterceptor,
  };
};

/**
 * 用于做异常处理的hook
 * 提供了addListener给外部监听指定类型的socket消息
 * @param connectResult 调用useWebSocket后的结果
 * @returns {{addResListener: (function(type: string, fn: (data: object) => void): void)}}
 */
export const useInterceptors = (connectResult) => {
  const { on: addResListener } = useResponseInterceptors(connectResult);

  addResListener(SocketTypeEnum.WARNING, (data) => {
    const { msg } = data;
    console.warn(data);
    ElMessage.warning({
      message: msg,
    });
  });

  addResListener(SocketTypeEnum.ERROR, (data) => {
    const { msg } = data;
    console.error(data);
    ElMessage.error({
      message: msg,
    });
  });

  return {
    addResListener,
  };
};
