/**
 * 对Post请求的参数进行转换
 */
export class PostDataTransform {
  data;

  constructor(data = {}) {
    this.data = data;
  }

  _transform(transformClass) {
    const newData = new transformClass();
    Object.keys(this.data).forEach((key) => {
      newData.append(key, this.data[key]);
    });
    return newData;
  }

  toFormData() {
    return this._transform(FormData);
  }

  toURLSearchParams() {
    return this._transform(URLSearchParams);
  }

  isHasFile() {
    return Object.values(this.data).some((item) => {
      return item instanceof FileList || item instanceof File || item instanceof Blob;
    });
  }

  autoTransform() {
    const hasFile = this.isHasFile();

    if (hasFile) {
      return this.toFormData();
    }

    return this.toURLSearchParams();
  }
}
