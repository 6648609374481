import axios from 'axios';
import { ElMessage } from 'element-plus';
import { PostDataTransform } from '@/utils/axios/data';
import config from '@/config';
import useUserStore from '@/store/user';
import router from '@/router';

function tokenException(code) {
  const codes = [1010, 3303, 1007];
  return codes.includes(code);
}

const setHeaderTokenInterceptors = (config) => {
  // 设置token
  const { headers } = config;
  if (headers.Authorization || headers['yqw-token']) return;

  const { teacher_uid } = router.currentRoute.value.params;
  if (!teacher_uid) return;

  const token = useUserStore().getTeacherTokenByUid(teacher_uid);
  if (!token) return;

  headers.Authorization = token;
  headers['yqw-token'] = token;
};

const _axios = axios.create({
  baseUrl: config.baseUrl,
  timeout: 20 * 1000,
  validateStatus(status) {
    return status >= 200 && status < 510;
  },
  headers: {
    platform: config.platform,
  },
});

_axios.interceptors.request.use(
  (originConfig) => {
    const reqConfig = {
      showErrorMsg: true,
      ...originConfig,
    };

    // step1: 容错处理
    if (!reqConfig.url) {
      console.error('request need url');
    }

    reqConfig.method = reqConfig.method.toLowerCase(); // 大小写容错
    if (reqConfig.method === 'get') {
      if (!reqConfig.params) {
        reqConfig.params = reqConfig.data || {};
      }
    } else if (reqConfig.method === 'post') {
      if (!reqConfig.data) {
        reqConfig.data = reqConfig.params || {};
      }
      reqConfig.data = new PostDataTransform(reqConfig.data).autoTransform();
    }

    setHeaderTokenInterceptors(reqConfig);

    return reqConfig;
  },

  (error) => Promise.reject(error),
);

_axios.interceptors.response.use(
  (res) => {
    if (!res.data) {
      ElMessage.error('没能从后端请求到数据');
      return Promise.reject(res);
    }

    const { data } = res;
    const { code, msg } = data;

    if (res.status.toString().charAt(0) === '2' && [1, 200].includes(code)) {
      return data.data;
    }

    if (tokenException(code)) {
      ElMessage.error(msg);
      const userStore = useUserStore();
      userStore.reset();
      // 登出
      setTimeout(() => {
        const { origin } = window.location;
        window.location.href = origin + '/login';
      }, 1500);

      return Promise.reject(data);
    }

    if (res.config.handleError) {
      return Promise.reject(data);
    }

    if (res.config.showErrorMsg) {
      ElMessage.error(msg);
    }
    return Promise.reject(data);
  },

  (error) => {
    console.log(error);
    if (error.code === 'ERR_CANCELED') {
      console.log('取消了请求');
      return Promise.reject(error);
    }

    if (!error.response) {
      ElMessage.error('请检查 API 是否异常');
      console.log('error', error);
    }

    // 判断请求超时
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      ElMessage.warning('请求超时');
    }

    return Promise.reject(error);
  },
);

export const get = (url, params = {}, config = {}) => {
  return _axios.get(url, {
    params,
    ...config,
  });
};

export const post = _axios.post;

export default _axios;
