import { computed, createApp, ref, watch } from 'vue';
import { useBase64, useFavicon } from '@vueuse/core';
import Favicon from '@/components/Favicon.vue';
import useNotice from '@/hooks/useNotice';

function useFaviconCount() {
  const html = ref('');
  const { base64 } = useBase64(html);

  // 设置favicon
  useFavicon(computed(() => 'data:image/svg+xml' + base64.value.slice('data:text/plain'.length)));

  const notice = useNotice();

  const el = document.createElement('div');
  const app = createApp(Favicon, {
    value: notice.value,
  });
  const instance = app.mount(el);

  watch(
    notice,
    (value, oldValue, onCleanup) => {
      instance.$.props.value = value;
      instance.$nextTick(() => {
        html.value = el.innerHTML;
      });
    },
    {
      immediate: true,
    },
  );
}

export default useFaviconCount;
