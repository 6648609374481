import { post, get } from '@/utils/axios';
import { isString } from 'lodash-es';
import useUserStore from '@/store/user';
import { ElMessage } from 'element-plus';

class User {
  static async login({ username, password }, showErrorMsg = true) {
    return await post(
      '/v1/sale/login',
      {
        username,
        password,
      },
      {
        showErrorMsg,
      },
    );
  }

  /**
   * 校验账号是否被冻结
   * @returns {Promise<unknown>}
   */
  static async checkAccountState() {
    const { userInfo } = useUserStore();
    if (!userInfo) return;

    const { id } = userInfo;

    try {
      await post('/v1/sale/login', {
        id,
      });
    } catch (e) {
      if (!e || !e.code) {
        ElMessage.error({
          message: '请刷新后重试',
          duration: 0,
        });
        return Promise.reject(e);
      }

      // 如果是冻结状态，则清除所有缓存
      if (e.code === 401) {
        sessionStorage.clear();
        localStorage.clear();
        setTimeout(() => {
          location.reload();
        }, 1500);
      }

      return Promise.reject(e);
    }
  }

  static async setUserRemarks({ uid, remark, nickname_remark, token }) {
    const data = {
      uid,
    };

    if (isString(remark)) {
      data.remark = remark;
    }
    if (isString(nickname_remark)) {
      data.nickname_remark = nickname_remark;
    }

    return post('/api/user/teacher/set_user_mark', data, {
      headers: {
        Authorization: token,
        'yqw-token': token,
      },
    });
  }
}

export default User;
