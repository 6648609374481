import { watchEffect } from 'vue';
import { useIntervalFn, useTitle } from '@vueuse/core';
import { useRoute } from 'vue-router';
import useUserStore from '@/store/user';
import config from '@/config';
import useNotice from '@/hooks/useNotice';

/**
 * 有未读消息时，会自动滚动显示数量
 */
export const useRollupNotifyTitle = () => {
  const title = useTitle(config.documentTitle);
  const notice = useNotice();

  watchEffect((onCleanup) => {
    if (!notice.value) {
      title.value = config.documentTitle;
      return;
    }

    let _title = ` 有 ${notice.value} 条新消息，请及时回复！！！ `;
    const { pause } = useIntervalFn(() => {
      title.value = _title;
      _title = _title.slice(1) + _title.slice(0, 1);
    }, 250);

    onCleanup(pause);
  });
};
