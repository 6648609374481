export const ChatTopEnum = {
  TOP: 1, // 置顶
  NOT_TOP: 0, // 不是置顶
};

export const ChatType = {
  ALL: 1, //全部
  NEW: 2, //新消息
  UNANSWERED: 3, //未回复
  KEY_CUSTOMERS: 4, // 重要老客
  PAID_CHATROOM: 5, //付费咨询室
  CARD_CLIENT: 6, //牌阵客户
  HISTORY_CHATROOM: 8, //历史咨询室
  COFFEE: 9, //咖啡厅客户
};

export const RETURN_VISIT = 999; // 每日回访

export const ProvideKey = {
  ROOM_INFO: Symbol(),
};
