/**
 * 存储老师的tokens
 * @param tokenList
 */
export const saveTeacherTokens = (tokenList) => {
  if (!Array.isArray(tokenList)) {
    throw new Error('teacher tokens必须是一个数组');
  }

  if (!tokenList.length) {
    return;
  }

  sessionStorage.setItem('teacher_tokens', tokenList.join(','));
};

/**
 * 获取存储的老师tokens
 * @returns {string[]|*[]}
 */
export const getTeacherTokens = () => {
  const teacherTokens = sessionStorage.getItem('teacher_tokens') || '';
  if (!teacherTokens) {
    return [];
  }

  return teacherTokens.split(',');
};
