import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import useUserStore from '@/store/user';
import { ElMessage, ElNotification } from 'element-plus';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
    return;
  }

  const userStore = useUserStore();
  if (!userStore.hasLogin) {
    ElNotification.info({
      title: '未登录',
      message: '登录后才能继续进行访问',
      type: 'warning',
      duration: 3000,
    });
    router.replace({
      path: '/login',
    });
    return;
  }

  next();
});

export default router;
