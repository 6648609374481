import { get, post } from '@/utils/axios';
import { ElMessage } from 'element-plus';
import { isNull } from 'lodash-es';
import { ChatType } from '@/enum/chat';

class Message {
  static async getFreeChatList({
    page = 1,
    size = 20,
    token,
    signal,
    nickname,
    type = ChatType.ALL,
  }) {
    const params = {
      page,
      size,
      type,
    };

    if (nickname) {
      params.nickname = nickname;
    }

    const res = await get('/api/message/free_chat/list', params, {
      headers: {
        Authorization: token,
      },
      signal,
    });

    return res;
  }

  /**
   * 是否置顶
   * @returns {Promise<void>}
   */
  static async setChatToTop({ room_id, is_top, token }) {
    if (!room_id || isNull(is_top)) {
      ElMessage.error({
        message: '必须传入room_id与is_top参数',
      });
      return;
    }

    return post(
      '/v1/freechat/set',
      {
        room_id,
        is_top,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  static getNotReadNum(token) {
    return get(
      '/api/comm/notice',
      {},
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  static createRoom({ user_id, token, signal }) {
    return post(
      '/v1/freechat/create',
      {
        user_id,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
        signal,
      },
    );
  }

  /**
   * 语音转文字
   * @param room_id
   * @param msg_id
   * @param token
   * @returns {Promise<AxiosResponse<any>>}
   */
  static transformAudioToText({ room_id, msg_id, token }) {
    return get(
      '/api/comm/voice/trans',
      {
        room_id,
        msg_id,
        type: 1,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  static getCustomerInfo({ uid, token }) {
    return get(
      '/api/user/customer_info',
      {
        uid,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  // 获取用户标签
  static async getClientTag({ user_id, token }) {
    return get(
      '/api/user/tag',
      {
        uid: user_id,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  static resetRemainderChatNum({ room_id, token }) {
    return post(
      '/api/free_chat/reset/remainder_chat_num',
      {
        room_id,
      },
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }

  // 聊天列表-每日回访
  static getEverydayReturnVisit({ token }) {
    return get(
      '/api/message/free_chat/everyday_return_visit',
      {},
      {
        headers: {
          Authorization: token,
          'yqw-token': token,
        },
      },
    );
  }
}

export default Message;
