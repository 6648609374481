import queryString from 'query-string';
import config from '@/config';
import { IDENTITY_TYPE } from '@/enum/webSocket';

/**
 * 获取webSocket的连接地址
 * @param room_id
 * @param from_uid
 * @param identity_type
 * @param otherQuery
 * @returns {string}
 */
export const getSocketUrl = ({
  room_id,
  from_uid,
  identity_type = IDENTITY_TYPE.SALE,
  ...otherQuery
}) => {
  return queryString.stringifyUrl(
    {
      url: config.webSocketUrl,
      query: {
        room_id,
        from_uid,
        identity_type,
        platform: config.platform,
        ...otherQuery,
      },
    },
    {
      skipNull: true,
    },
  );
};
