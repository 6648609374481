import { useWebSocket } from '@vueuse/core/index';
import dayjs from 'dayjs';
import { useInterceptors } from '@/hooks/webSocket/interceptors';
import { getSocketUrl } from '@/utils/webSocket';
import { watch } from 'vue';
import { useIntervalFn } from '@vueuse/core';

/**
 * useWebSocket心跳机制的参数
 * @type {{interval: number, message: string}}
 */
export const heartbeatSetting = {
  message: JSON.stringify({
    type: 'ping',
    time: dayjs().unix(),
    data: {},
  }),
  interval: 20000,
};

/**
 *
 * @param connectResult {import('@vueuse/core').UseWebSocketReturn}
 */
const useHeartbeat = (connectResult) => {
  const { pause, resume } = useIntervalFn(
    () => {
      connectResult.send(heartbeatSetting.message, false);
    },
    heartbeatSetting.interval,
    { immediate: false },
  );

  watch(connectResult.status, (value) => {
    if (value === 'OPEN') {
      resume();
    } else {
      pause();
    }
  });
};

export const useConnectSocket = (
  name = '',
  // 看getSocketUrl(query)的类型
  query = {
    room_id: undefined,
    from_uid: undefined,
    identity_type: undefined,
  },
  options = {
    debug: false,
    debugName: '',
    onReconnectFailed: null,
  },
) => {
  const url = getSocketUrl(query);

  const { debug, debugName, ...otherOptions } = options;

  let _options = otherOptions;

  if (debug) {
    _options = {
      onConnected: (ws) => {
        console.log(`${debugName}：socket已连接`);
      },

      onMessage: (ws, event) => {
        console.log(`${debugName}：${event}`);
      },

      onError: (ws, event) => {
        console.log(`${debugName}：${event}`);
        console.log(`${debugName}：error`);
      },

      onDisconnected: (ws, event) => {
        console.log(`${debugName}：${event}`);
        console.log(`${debugName}：disconnected`);
      },
      ..._options,
    };
  }

  const connectResult = useWebSocket(url, {
    autoReconnect: {
      retries: -1,
      delay: 2000,
      onFailed() {
        if (typeof options?.onReconnectFailed === 'function') {
          options.onReconnectFailed();
        }
      },
    },
    heartbeat: false,
    onDisconnected: (ws, event) => {
      console.log(`${dayjs().format('YYYY-MM-DD HH:mm:ss')} onDisconnected: `, event.code, event);
    },
    ..._options,
  });

  // 因为useWebSocket在v10的版本的heartbeat机制增加了需要pong的响应
  // 但由于后端并没有做pong的相应，时间关系先前端自行ping，不依赖于useWebSocket的内部机制
  useHeartbeat(connectResult);

  const { addResListener } = useInterceptors(connectResult);

  return {
    ...connectResult,
    addResListener,
  };
};
